import React from 'react';
import { Bell, Calendar, Wrench, Home, Wifi, Tag } from 'lucide-react';

interface Announcement {
  title: string;
  date: string;
  content: string;
  category: string;
  icon: keyof typeof icons;
}

const icons = {
  bell: Bell,
  calendar: Calendar,
  tool: Wrench,
  home: Home,
  wifi: Wifi,
  tag: Tag
};

export default function Announcements() {
  const announcements: Announcement[] = [
    {
      title: "Community Estate Sale",
      date: "May 3, 2025",
      category: "Community Event",
      icon: "tag",
      content: "Want to do a little spring cleaning? You're in luck! Sycamore Creek will be holding their Annual Community Estate Sale on Saturday, May 3, 2025."
    },
    {
      title: "Community Poll: Exciting Opportunity for High-Speed Internet!",
      date: "",
      category: "Community Update",
      icon: "wifi",
      content: "We're thrilled to share that Cityside Fiber is considering bringing high-speed fiberoptic internet to our community. This upgrade would mean faster speeds, more reliable connections, and better service options for residents and businesses.\n\nCityside Fiber is currently evaluating interest from our neighborhood. To help make this happen, we encourage everyone to express their support by "
    },
    {
      title: "Painting Notice",
      date: "",
      category: "Maintenance",
      icon: "home",
      content: "You may have noticed several of the homes inside Sycamore Creek have visible weather and sun damage on the stucco, wood siding, facia, and garage doors. Management would like to give you notice that with the upcoming rainy season, it is best to have your exterior painting scheduled now. We will be giving notices to the houses with visible damage that they will need to repaint their homes. Please remember that before making any exterior improvements to your home, an architectural application must be submitted for review, and approved before the work and changes are started. If you need a copy of the architectural application, please contact your Property Manager, Melanie Clay, at mclay@keystonepacific.com, to have one sent to you."
    },
    {
      title: "Front Yard Maintenance",
      date: "April 2025",
      category: "Maintenance",
      icon: "tool",
      content: "With Spring on the way, please keep in mind that front yard maintenance needs to be performed regularly. Please trim bushes and other plant materials off the sidewalk to avoid tripping hazards. Weeds are troublesome and unappealing when they appear in the cracks of your walkway, sidewalks, and driveways. Please take a few minutes and remove the weeds around your home. This will help to improve the look of your home and the community. Thank you for taking the time in keeping your yard looking clean and well maintained."
    },
    {
      title: "Digital Architectural Submissions Now Available",
      date: "",
      category: "Community Update",
      icon: "bell",
      content: "Exciting news! Our platform has been updated to streamline the architectural submission for you. You can now submit architectural requests directly on the Architectural page at keystoneconnection.net. This enhancement will expedite the review process, ensuring you receive a prompt decision.\n\nHere's how to get started:\n• Download the application from the Document Library (Community Information > Documents > Architectural)\n• Complete the Arch App and collect neighbors' signatures.\n• Complete online form and upload Application on Portal (Account Info > Architectural > Add ACC Request)\n\nLet's make the Architectural submission process smoother and more efficient together!"
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Community Announcements</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Stay informed about important updates and news from your Sycamore Creek community.
          </p>
        </div>

        <div className="space-y-6">
          {announcements.map((announcement, index) => {
            const IconComponent = icons[announcement.icon];
            return (
              <div key={index} className="bg-gradient-to-r from-indigo-50 to-white rounded-lg p-6 shadow-sm">
                <div className="flex items-start gap-4">
                  <div className="bg-indigo-100 rounded-full p-3 mt-1">
                    <IconComponent className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-xl font-semibold">{announcement.title}</h3>
                      <span className="text-sm text-indigo-600 font-medium bg-indigo-50 px-3 py-1 rounded-full inline-flex items-center justify-center">
                        {announcement.category}
                      </span>
                    </div>
                    <div className="text-sm text-gray-500 mb-3">
                      {announcement.date}
                    </div>
                    <p className="text-gray-700 leading-relaxed whitespace-pre-line">
                      {announcement.content}
                      {announcement.title === "Community Poll: Exciting Opportunity for High-Speed Internet!" && (
                        <a 
                          href="https://forms.gle/rn4nLLEouyWfuccA9" 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-indigo-600 hover:text-indigo-700 underline"
                        >
                          filling out a short interest form here
                        </a>
                      )} 
                      {announcement.title === "Community Poll: Exciting Opportunity for High-Speed Internet!" && ".\n\nStay tuned for updates, and let's work together to bring cutting-edge internet to our community."}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}