import { format, addDays, startOfMonth, endOfMonth, startOfWeek, isBefore, parseISO, isFuture, getYear } from 'date-fns';
import { nextThursday } from 'date-fns/fp';
import { zonedTimeToUtc, utcToZonedTime, format as formatTZ } from 'date-fns-tz';
import { EventType, EventCategory, RecurringFrequency } from './eventTypes';

const TIMEZONE = 'America/Los_Angeles';

export interface Location {
  address: string;
  phone?: string;
}

export interface Event {
  id: string;
  title: string;
  startDate: string;
  endDate?: string;
  time?: string;
  location?: Location;
  description: string;
  type: EventType;
  category: EventCategory;
  recurring?: {
    frequency: RecurringFrequency;
    dayOfWeek: number;
    weekOfMonth?: number;
  };
}

// Convert date to PST
export const toPST = (date: Date): Date => {
  return utcToZonedTime(date, TIMEZONE);
};

// Convert PST to UTC
export const fromPST = (date: Date): Date => {
  return zonedTimeToUtc(date, TIMEZONE);
};

// Format date in PST
export const formatInPST = (date: Date, formatStr: string): string => {
  return formatTZ(toPST(date), formatStr, { timeZone: TIMEZONE });
};

// Generate all trash pickup dates for a given month
export const generateTrashPickupDates = (startDate: Date, endDate: Date) => {
  const dates: Date[] = [];
  let currentDate = nextThursday(startOfWeek(toPST(startDate)));
  
  while (isBefore(currentDate, endDate)) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 7);
  }
  
  return dates;
};

// Calculate first and third Wednesday of a given month
export const getStreetSweepingDates = (date: Date) => {
  const firstDayOfMonth = startOfMonth(toPST(date));
  let firstWednesday = firstDayOfMonth;
  while (firstWednesday.getDay() !== 3) {
    firstWednesday = addDays(firstWednesday, 1);
  }
  const thirdWednesday = addDays(firstWednesday, 14);
  
  return { firstWednesday, thirdWednesday };
};

const getUSHolidays = (date: Date): Event[] => {
  const year = getYear(date);
  return [
    {
      id: `new-years-${year}`,
      title: "New Year's Day",
      startDate: `${year}-01-01`,
      description: "New Year's Day Holiday",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `mlk-${year}`,
      title: "Martin Luther King Jr. Day",
      startDate: `${year}-01-15`,
      description: "Martin Luther King Jr. Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `presidents-${year}`,
      title: "Presidents' Day",
      startDate: `${year}-02-19`,
      description: "Presidents' Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `memorial-${year}`,
      title: "Memorial Day",
      startDate: `${year}-05-27`,
      description: "Memorial Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `juneteenth-${year}`,
      title: "Juneteenth",
      startDate: `${year}-06-19`,
      description: "Juneteenth National Independence Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `independence-${year}`,
      title: "Independence Day",
      startDate: `${year}-07-04`,
      description: "Independence Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `labor-${year}`,
      title: "Labor Day",
      startDate: `${year}-09-02`,
      description: "Labor Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `columbus-${year}`,
      title: "Columbus Day",
      startDate: `${year}-10-14`,
      description: "Columbus Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `veterans-${year}`,
      title: "Veterans Day",
      startDate: `${year}-11-11`,
      description: "Veterans Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `thanksgiving-${year}`,
      title: "Thanksgiving Day",
      startDate: `${year}-11-28`,
      description: "Thanksgiving Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    },
    {
      id: `christmas-${year}`,
      title: "Christmas Day",
      startDate: `${year}-12-25`,
      description: "Christmas Day",
      type: EventType.HOLIDAY,
      category: EventCategory.HOLIDAY
    }
  ];
};

export const getEventsForMonth = (date: Date): Event[] => {
  const { firstWednesday, thirdWednesday } = getStreetSweepingDates(date);
  const monthStart = startOfMonth(toPST(date));
  const monthEnd = endOfMonth(toPST(date));

  // Base events
  const events: Event[] = [
    {
      id: 'board-meeting-apr-2025',
      title: 'Board Meeting',
      startDate: '2025-04-10',
      time: '6:00 PM',
      location: {
        address: '240 Commerce, Suite 200, Irvine, CA 92602',
        phone: '(949) 403-7405'
      },
      description: 'General Session at Keystone RSM Office. The final agenda will be posted at Raintree Gate. You may also obtain a copy of the agenda by contacting management at reconnect@keystonepacific.com',
      type: EventType.MEETING,
      category: EventCategory.MEETING
    },
    {
      id: 'community-estate-sale-2025',
      title: 'Community Estate Sale',
      startDate: '2025-05-03',
      description: 'Annual Community Estate Sale. Perfect opportunity for spring cleaning and finding great deals in our community!',
      type: EventType.COMMUNITY,
      category: EventCategory.FEATURED
    },
    {
      id: 'street-sweep-1',
      title: 'Street Sweeping',
      startDate: formatInPST(firstWednesday, 'yyyy-MM-dd'),
      time: '9:00 AM',
      description: `Next street sweeping dates: ${formatInPST(firstWednesday, 'MMM d')} and ${formatInPST(thirdWednesday, 'MMM d')}. Please move vehicles for scheduled street sweeping.`,
      type: EventType.MAINTENANCE,
      category: EventCategory.ROUTINE,
      recurring: {
        frequency: RecurringFrequency.BIMONTHLY,
        dayOfWeek: 3,
        weekOfMonth: 1
      }
    },
    {
      id: 'street-sweep-2',
      title: 'Street Sweeping',
      startDate: formatInPST(thirdWednesday, 'yyyy-MM-dd'),
      time: '9:00 AM',
      description: `Next street sweeping dates: ${formatInPST(firstWednesday, 'MMM d')} and ${formatInPST(thirdWednesday, 'MMM d')}. Please move vehicles for scheduled street sweeping.`,
      type: EventType.MAINTENANCE,
      category: EventCategory.ROUTINE,
      recurring: {
        frequency: RecurringFrequency.BIMONTHLY,
        dayOfWeek: 3,
        weekOfMonth: 3
      }
    }
  ];

  // Add trash pickup events
  const trashPickupDates = generateTrashPickupDates(monthStart, monthEnd);
  const trashPickupEvents = trashPickupDates.map((date) => ({
    id: `trash-pickup-${formatInPST(date, 'yyyy-MM-dd')}`,
    title: 'Trash Pickup',
    startDate: formatInPST(date, 'yyyy-MM-dd'),
    time: '7:00 AM',
    description: 'Weekly trash collection. Please remove trash cans from common areas after collection. Note: Collection may be delayed to Friday following holidays.',
    type: EventType.MAINTENANCE,
    category: EventCategory.ROUTINE,
    recurring: {
      frequency: RecurringFrequency.WEEKLY,
      dayOfWeek: 4
    }
  }));

  // Add US holidays
  const holidays = getUSHolidays(date);

  return [...events, ...trashPickupEvents, ...holidays];
};

export const getUpcomingEvents = (date: Date = new Date()) => {
  const events = getEventsForMonth(toPST(date));
  
  // Filter out past events and holidays
  const futureEvents = events
    .filter(event => 
      isFuture(parseISO(event.startDate)) && 
      event.type !== EventType.HOLIDAY
    )
    .sort((a, b) => parseISO(a.startDate).getTime() - parseISO(b.startDate).getTime());

  // For maintenance events, only show the next occurrence per type
  const seenMaintenanceTypes = new Set<string>();
  return futureEvents.filter(event => {
    // If it's not a maintenance event, include it
    if (event.type !== EventType.MAINTENANCE) {
      return true;
    }

    // For maintenance events, only show the first upcoming occurrence of each title
    const maintenanceKey = event.title;
    if (!seenMaintenanceTypes.has(maintenanceKey)) {
      seenMaintenanceTypes.add(maintenanceKey);
      return true;
    }
    return false;
  });
};